import NextI18Next from 'next-i18next';

const newI18n = new NextI18Next({
  otherLanguages: ['es'],
  defaultNS: 'common',
  localeSubpaths: {
    es: 'es',
  },
  localePath: 'public/static/locales',
  defaultLanguage: 'en',
  browserLanguageDetection: false,
  serverLanguageDetection: false,
});

export default newI18n;
